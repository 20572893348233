@import "src/styles/animate/animate";

.fade_in_left {
  @include fadeInLeft();
}

.fade_in {
  @include fadeIn()
}

.opacity_parent {
  .opacity_child {
    opacity: 0;
  }

  &:hover {
    .opacity_child {
      opacity: 1;
    }
  }
}

.youtube_parent {
  .icon {
    top: calc(50% - 1em);
  }

  svg {
    font-size: 2em;
  }

  &:hover {
    .icon {
      top: calc(50% - 2em);
    }

    svg {
      font-size: 4em;
    }
  }

  @media (max-width: 576px) {
    .icon {
      top: calc(50% - 1.5em);
    }

    svg {
      font-size: 3em;
    }
  }
}

@keyframes toast_fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes toast_fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

.toast_animation {
  animation: toast_fadein 0.5s, toast_fadeout 0.5s 2.5s;
}

.popup_animation {
  transition-property: max-height;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}


