.tooltip-container {
  padding-top: 10px;
  z-index: 1000 !important;
}

.slider-row {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.slider-button-shadow {
  box-shadow: 0px 3.7599847316741943px 33.422088623046875px 0px #0000000d;

  //box-shadow: 0px 9px 80px 0px #00000012;
}
