.phone_input {
  .PhoneInput {
    padding: 12px 16px;
    border-radius: 4px;
    border-width: 2px;
    border-color: transparent;
    background-color: white;

    &--focus {
      border-color: #0dad8e;
    }

    .PhoneInputCountry {
      margin-right: 12px;
    }

    input {
      border: none;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  &--error {
    border-color: #ef2547 !important;
  }
}
