.break-space {
  white-space: break-spaces;
}

.grid-col-auto {
  grid-template-columns: auto auto;
}

.text-base-responsive {
  font-size: 16px !important;

  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}

.slider-row {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
}

.slider-mobile {
  @media (max-width: 576px) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-align: center;
    padding-right: 0;
    width: 100%;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.markup {
  li,
  p,
  span {
    font-size: 14px !important;
  }

  p {
    margin-bottom: 1rem;
  }

  li {
    list-style: disc;
  }

  @media (max-width: 576px) {
    li,
    p,
    span {
      font-size: 12px !important;
    }
  }
}

.markup_list {
  margin-bottom: 10px;
}

.overflow-auto {
  overflow: auto;
}
